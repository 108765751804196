var equalheight = function (container) {
    if (jQuery(window).width() > 767) {
        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            jQueryel;

        jQuery(container).each(function () {
            jQueryel = jQuery(this);
            jQuery(jQueryel).innerHeight('auto');
            rowDivs.push(jQueryel);
            currentTallest = (currentTallest < jQueryel.innerHeight()) ? (jQueryel.innerHeight()) : (currentTallest);

            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].innerHeight(currentTallest);
            }
        });
    } else {
        jQuery(container).height('auto');
    }
};

function globle() {
    equalheight('.upcoming-events h4');
    equalheight('.upcoming-events .image-div img');
    jQuery("select.goog-te-combo option:first-child").text('Translate This Site');

    new ScrollHint(".js-scrollable");
}

jQuery(document).ready(function() {
    //new Menu Responsive
    jQuery(".mobile-icon a").on("click touchstart", function (event) {
        event.preventDefault();
        event.stopPropagation();
        jQuery("body").toggleClass("open-menu");

        var NAVIGATION = document.getElementById("menu-main-menu");
        var OPEN_ITEMS = NAVIGATION.querySelectorAll(".open");

        OPEN_ITEMS.forEach(function (menu_item) {
            menu_item.classList.remove("open");
        });

    });

    jQuery(".search-section .search-bar").on("click touchstart", function (event) {
        event.preventDefault();
        event.stopPropagation();
        jQuery("body").toggleClass("open-menu");

        document.querySelector(".header-right input[name=s]").focus();
    });

    jQuery(document).on('click touchstart', function () {
        jQuery('body').removeClass('open-menu');
    });

    jQuery(".site-header .header-right").on("click touchstart", function (event) {
        event.stopPropagation();
    });

    /*search*/
    jQuery(".search-section .search-bar").click(function (event) {
        event.stopPropagation();
        jQuery(this).next(".search-form").toggleClass("is-active");
        jQuery(this).next(".search-form").find("input").focus();
    });

    jQuery(document).on("click", function (e) {
        var popup = jQuery(".search-section");
        if (!jQuery('.search-form').is(e.target) && !popup.is(e.target) && popup.has(e.target).length == 0) {
            jQuery(".search-form").removeClass("is-active");
        }
    });

    /**/
    jQuery(".banner-slider").slick({
        dots: (jQuery(this).find('.banner-slider .item').length > 1) ? true : false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1 ,
        autoplay: true,
        autoplaySpeed: 2000,
    });

    jQuery('.responsive-slider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        responsive: [
                {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 767,
                settings: "unslick"
            }
        ]
    });

    function close_accordion_section() {
        jQuery('.accordion .accordion-section-title').removeClass('active');
        jQuery('.accordion .accordion-section-content').slideUp(300).removeClass('open');
    }

    jQuery('.accordion-section-title').click(function(e) {
        // Grab current anchor value
        var currentAttrValue = jQuery(this).attr('href');

        if(jQuery(e.target).is('.active')) {
            close_accordion_section();
        }else {
            close_accordion_section();

            // Add active class to section title
            jQuery(this).addClass('active');
            // Open up the hidden content panel
            jQuery('.accordion ' + currentAttrValue).slideDown(300).addClass('open');
        }

        e.preventDefault();
    });

    globle();
});

jQuery(window).load(function() {
    globle();
});

jQuery(window).resize(function() {
    globle();
});
