// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

window.addEventListener("DOMContentLoaded", function () {
    var NAVIGATION = document.getElementById("menu-main-menu");
    var PARENT_ITEMS = NAVIGATION.querySelectorAll(".menu-item-has-children");

    var CLOSE_ALL_ITEMS = function () {
        PARENT_ITEMS.forEach(function (menu_item) {
            menu_item.classList.remove("open");
        });
    };

    PARENT_ITEMS.forEach(function (menu_item) {
        var LINK = menu_item.querySelector("a");
        var TOGGLE = menu_item.querySelector("button");

        /**
         * Just return if a link or toggle doesn't exist
         */
        if (!LINK || !TOGGLE) return;

        /**
         * Open child items on first touch on link
         */
        LINK.addEventListener("touchstart", function (e) {
            if (!menu_item.classList.contains("open")) {
                e.preventDefault();

                CLOSE_ALL_ITEMS();

                menu_item.classList.add("open");
            }
        });

        /**
         * Open child items on click on button
         */
        TOGGLE.addEventListener("click", function (e) {
            e.preventDefault();

            var OPEN = menu_item.classList.contains("open");

            CLOSE_ALL_ITEMS();

            if (!OPEN) {
                menu_item.classList.add("open");
            }
        });
    });
});
